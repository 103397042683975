
import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "setup",
  data: () => ({
    roots: [],
    cards: [
      {
        title: "Link Bundles",
        routeName: "bundles",
        flex: 12,
      },
      {
        title: "Files",
        routeName: "files",
        flex: 6,
      },
      {
        title: "Items",
        routeName: "items",
        flex: 6,
      },
    ],
  }),
  async mounted() {
    const rootreq = await axios.get("http://localhost:8080/syncs");
    this.roots = rootreq.data;
  },
  methods: {
    goto(routeName: string) {
      this.$router.push({ name: routeName });
    },
  },
});
